/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@font-face {
    font-family: 'Calibri (Bold)';
    font-weight: normal;
    font-display: swap;
    font-style: normal;
    // font-named-instance: 'Regular';
    src: url('/fonts/calibri/Calibri-Bold.woff2') format('woff2'),
        url('/fonts/calibri/Calibri-Bold.woff') format('woff');
}

.bg-connect-nav {
    // background-color: #fcfacc;
    @apply bg-primary-500;
}

.mdc-button {
    color: black !important;
}
.mat-mdc-dialog-component-host .text-secondary {
    text-align: justify !important;
}
.e-grid .e-columnchooser-btn::before {
    content: "\e714";  /* Use your desired icon code */
}

.e-ddl.e-input-group.e-control-wrapper .e-ddl-icon::before {
    content: "\e916";
}

.e-checkbox-wrapper .e-check::before, .e-css.e-checkbox-wrapper .e-check::before {
    content: "\e7ff";
}
