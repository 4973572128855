/* stylelint-disable-line no-empty-source */
.e-dropdown-btn .e-caret::before,
.e-dropdown-btn.e-btn .e-caret::before {
  content: "\e729";
}

/*! drop-down button layout */
.e-dropdown-btn,
.e-dropdown-btn.e-btn {
  box-shadow: none;
  text-transform: none;
  white-space: normal;
}
.e-dropdown-btn:hover,
.e-dropdown-btn.e-btn:hover {
  box-shadow: none;
}
.e-dropdown-btn:focus,
.e-dropdown-btn.e-btn:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
}
.e-dropdown-btn:active,
.e-dropdown-btn.e-btn:active {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.e-dropdown-btn .e-btn-icon,
.e-dropdown-btn.e-btn .e-btn-icon {
  font-size: 14px;
}
.e-dropdown-btn .e-caret,
.e-dropdown-btn.e-btn .e-caret {
  font-size: 18px;
}
.e-dropdown-btn.e-vertical,
.e-dropdown-btn.e-btn.e-vertical {
  line-height: 1;
  padding: 6px 12px;
}
.e-dropdown-btn.e-caret-hide .e-caret,
.e-dropdown-btn.e-btn.e-caret-hide .e-caret {
  display: none;
}
.e-dropdown-btn.e-small .e-dropdown-popup ul,
.e-dropdown-btn.e-btn.e-small .e-dropdown-popup ul {
  padding: 4px 0;
}
.e-dropdown-btn.e-small .e-dropdown-popup ul .e-item,
.e-dropdown-btn.e-btn.e-small .e-dropdown-popup ul .e-item {
  padding: 0 8px;
}
.e-dropdown-btn.e-small.e-vertical,
.e-dropdown-btn.e-btn.e-small.e-vertical {
  line-height: 1;
  padding: 6px 12px;
}

.e-bigger .e-dropdown-btn .e-btn-icon,
.e-bigger.e-dropdown-btn .e-btn-icon {
  font-size: 16px;
}
.e-bigger .e-dropdown-btn .e-caret,
.e-bigger.e-dropdown-btn .e-caret {
  font-size: 22px;
}
.e-bigger .e-dropdown-btn.e-vertical,
.e-bigger.e-dropdown-btn.e-vertical {
  line-height: 1;
  padding: 8px 16px;
}
.e-bigger .e-dropdown-btn.e-small.e-vertical,
.e-bigger.e-dropdown-btn.e-small.e-vertical {
  line-height: 1;
  padding: 8px 16px;
}

.e-dropdown-popup {
  position: absolute;
  border-radius: 4px;
  margin-top: 4px;
}
.e-dropdown-popup ul {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  min-width: 120px;
  overflow: hidden;
  padding: 4px 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}
.e-dropdown-popup ul .e-item {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
}
.e-dropdown-popup ul .e-item.e-url {
  padding: 0;
}
.e-dropdown-popup ul .e-item .e-menu-url {
  display: block;
  padding: 0 12px;
  text-decoration: none;
  width: 100%;
}
.e-dropdown-popup ul .e-item .e-menu-icon {
  float: left;
  font-size: 18px;
  line-height: 30px;
  margin-right: 12px;
  vertical-align: middle;
  width: 1em;
}
.e-dropdown-popup ul .e-item.e-disabled {
  cursor: auto;
  pointer-events: none;
}
.e-dropdown-popup ul .e-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  cursor: auto;
  height: auto;
  line-height: normal;
  margin: 3px 0;
  pointer-events: none;
}
.e-dropdown-popup.e-transparent {
  background: transparent;
  box-shadow: none;
}
.e-dropdown-popup.e-transparent .e-dropdown-menu {
  border: 0;
  height: 1px;
  min-width: 0;
  padding: 0;
  width: 1px;
}

.e-rtl.e-dropdown-popup .e-item .e-menu-icon {
  float: right;
  margin-left: 12px;
  margin-right: 0;
}

*.e-bigger .e-dropdown-popup {
  background-color: transparent;
  border-radius: 4px;
}

*.e-bigger .e-dropdown-popup ul,
*.e-bigger.e-dropdown-popup ul {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  max-width: 202px;
  min-width: 112px;
  padding: 4px 0;
  background-color: #fff;
  margin-top: 4px;
}
*.e-bigger .e-dropdown-popup ul .e-item,
*.e-bigger.e-dropdown-popup ul .e-item {
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
}
*.e-bigger .e-dropdown-popup ul .e-item.e-url,
*.e-bigger.e-dropdown-popup ul .e-item.e-url {
  padding: 0;
}
*.e-bigger .e-dropdown-popup ul .e-item .e-menu-icon,
*.e-bigger.e-dropdown-popup ul .e-item .e-menu-icon {
  font-size: 22px;
  line-height: 36px;
}
*.e-bigger .e-dropdown-popup ul .e-item.e-separator,
*.e-bigger.e-dropdown-popup ul .e-item.e-separator {
  height: auto;
  line-height: normal;
}

/*! drop-down button theme */
.e-dropdown-popup {
  background: #fff;
  color: #374151;
}
.e-dropdown-popup ul .e-item .e-menu-url {
  color: #374151;
}
.e-dropdown-popup ul .e-item .e-menu-icon {
  color: #374151;
}
.e-dropdown-popup ul .e-item.e-focused {
  background: #f3f4f6;
  box-shadow: none;
}
.e-dropdown-popup ul .e-item:hover {
  background: #f3f4f6;
  color: #111827;
}
.e-dropdown-popup ul .e-item:active, .e-dropdown-popup ul .e-item.e-selected {
  background: #e5e7eb;
  color: #111827;
}
.e-dropdown-popup ul .e-item:active .e-menu-url, .e-dropdown-popup ul .e-item.e-selected .e-menu-url {
  color: #111827;
}
.e-dropdown-popup ul .e-item:active .e-menu-icon, .e-dropdown-popup ul .e-item.e-selected .e-menu-icon {
  color: #111827;
}
.e-dropdown-popup ul .e-separator {
  border-bottom-color: #e5e7eb;
}

.e-dropdown-btn.e-inherit,
.e-dropdown-btn.e-btn.e-inherit {
  background: inherit;
  border-color: transparent;
  box-shadow: none;
  color: inherit;
}
.e-dropdown-btn.e-inherit:hover, .e-dropdown-btn.e-inherit:focus, .e-dropdown-btn.e-inherit:active, .e-dropdown-btn.e-inherit.e-active,
.e-dropdown-btn.e-btn.e-inherit:hover,
.e-dropdown-btn.e-btn.e-inherit:focus,
.e-dropdown-btn.e-btn.e-inherit:active,
.e-dropdown-btn.e-btn.e-inherit.e-active {
  background: rgba(0, 0, 0, 0.056);
  border-color: transparent;
  box-shadow: none;
  color: inherit;
}

/* stylelint-disable-line no-empty-source */
/*! splitbutton layout */
.e-split-btn-wrapper {
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  width: min-content;
}
.e-split-btn-wrapper .e-split-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  text-transform: none;
  z-index: 1;
  padding-left: 12px;
  padding-right: 12px;
  padding-left: 10px;
  padding-right: 10px;
}
.e-split-btn-wrapper .e-split-btn:focus {
  outline-offset: 0;
}
.e-split-btn-wrapper .e-split-btn:hover {
  box-shadow: none;
}
.e-split-btn-wrapper .e-split-btn:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  z-index: 2;
}
.e-split-btn-wrapper .e-split-btn:active {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  z-index: 2;
}
.e-split-btn-wrapper .e-split-btn.e-top-icon-btn {
  padding-bottom: 10px;
  padding-top: 10px;
}
.e-split-btn-wrapper .e-split-btn .e-btn-icon {
  font-size: 14px;
  margin-left: 0;
  padding-right: 8px;
  width: auto;
}
.e-split-btn-wrapper .e-split-btn .e-icon-top {
  padding-bottom: 4px;
  padding-right: 0;
}
.e-split-btn-wrapper .e-split-btn.e-icon-btn {
  padding-left: 8px;
  padding-right: 8px;
}
.e-split-btn-wrapper .e-split-btn.e-icon-btn .e-btn-icon {
  padding-right: 0;
}
.e-split-btn-wrapper .e-dropdown-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 1;
}
.e-split-btn-wrapper .e-dropdown-btn:focus, .e-split-btn-wrapper .e-dropdown-btn:hover {
  box-shadow: none;
}
.e-split-btn-wrapper .e-dropdown-btn:active {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  z-index: 2;
}
.e-split-btn-wrapper .e-dropdown-btn:disabled:focus, .e-split-btn-wrapper .e-dropdown-btn:disabled:hover, .e-split-btn-wrapper .e-dropdown-btn:disabled:active {
  z-index: 0;
}
.e-split-btn-wrapper .e-dropdown-btn:focus {
  outline-offset: 0;
}
.e-split-btn-wrapper .e-btn {
  box-shadow: none;
}
.e-split-btn-wrapper .e-btn:hover {
  box-shadow: none;
}
.e-split-btn-wrapper .e-btn:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  z-index: 2;
}
.e-split-btn-wrapper .e-btn:active {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  z-index: 2;
}
.e-split-btn-wrapper .e-btn:disabled {
  box-shadow: none;
}
.e-split-btn-wrapper .e-btn.e-inherit:focus, .e-split-btn-wrapper .e-btn.e-inherit:active {
  box-shadow: none;
}
.e-split-btn-wrapper.e-rtl .e-split-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 4px;
  margin-left: -1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-left: 4px;
  padding-right: 10px;
}
.e-split-btn-wrapper.e-rtl .e-split-btn .e-btn-icon {
  margin-right: 0;
  padding-left: 8px;
  padding-right: 0;
}
.e-split-btn-wrapper.e-rtl .e-split-btn .e-icon-top {
  padding-left: 0;
}
.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn {
  padding-left: 8px;
  padding-right: 8px;
}
.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn .e-btn-icon {
  padding-left: 0;
}
.e-split-btn-wrapper.e-rtl .e-dropdown-btn {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  margin-left: 0;
  z-index: 1;
}
.e-split-btn-wrapper.e-vertical {
  display: inline-block;
}
.e-split-btn-wrapper.e-vertical .e-split-btn,
.e-split-btn-wrapper.e-vertical .e-dropdown-btn {
  display: block;
  width: 100%;
}
.e-split-btn-wrapper.e-vertical .e-split-btn {
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  line-height: 1;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-right: 10px;
}
.e-split-btn-wrapper.e-vertical .e-split-btn .e-icon-top {
  padding-bottom: 4px;
}
.e-split-btn-wrapper.e-vertical .e-dropdown-btn {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  line-height: 0.334em;
  margin-left: 0;
  margin-top: -1px;
  padding-bottom: 4px;
  padding-top: 4px;
}
.e-split-btn-wrapper.e-vertical .e-dropdown-btn .e-icon-bottom {
  padding-top: 0;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn {
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  margin-left: 0;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-dropdown-btn {
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
}

.e-bigger .e-split-btn-wrapper .e-split-btn,
.e-bigger.e-split-btn-wrapper .e-split-btn {
  padding-left: 16px;
  padding-right: 16px;
}
.e-bigger .e-split-btn-wrapper .e-split-btn.e-top-icon-btn,
.e-bigger.e-split-btn-wrapper .e-split-btn.e-top-icon-btn {
  padding-bottom: 12px;
  padding-top: 12px;
}
.e-bigger .e-split-btn-wrapper .e-split-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper .e-split-btn .e-btn-icon {
  font-size: 16px;
  margin-left: 0;
  padding-right: 10px;
  width: auto;
}
.e-bigger .e-split-btn-wrapper .e-split-btn .e-icon-top,
.e-bigger.e-split-btn-wrapper .e-split-btn .e-icon-top {
  padding-bottom: 6px;
  padding-right: 0;
}
.e-bigger .e-split-btn-wrapper .e-split-btn.e-icon-btn,
.e-bigger.e-split-btn-wrapper .e-split-btn.e-icon-btn {
  padding-left: 12px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper .e-split-btn.e-icon-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper .e-split-btn.e-icon-btn .e-btn-icon {
  padding-right: 0;
}
.e-bigger .e-split-btn-wrapper .e-dropdown-btn,
.e-bigger.e-split-btn-wrapper .e-dropdown-btn {
  padding-left: 4px;
  padding-right: 4px;
  z-index: 1;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn {
  padding-left: 12px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn .e-btn-icon {
  padding-left: 10px;
  padding-right: 0;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn .e-icon-top,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn .e-icon-top {
  padding-left: 0;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn {
  padding-left: 12px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn .e-btn-icon {
  padding-left: 0;
}
.e-bigger .e-split-btn-wrapper.e-vertical .e-split-btn,
.e-bigger.e-split-btn-wrapper.e-vertical .e-split-btn {
  padding-bottom: 12px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper.e-vertical .e-dropdown-btn,
.e-bigger.e-split-btn-wrapper.e-vertical .e-dropdown-btn {
  padding-bottom: 4px;
  padding-top: 4px;
  z-index: 1;
}
.e-bigger .e-split-btn-wrapper.e-vertical .e-dropdown-btn .e-icon-bottom,
.e-bigger.e-split-btn-wrapper.e-vertical .e-dropdown-btn .e-icon-bottom {
  padding-top: 0;
}

/*! splitbutton theme */
.e-split-btn-wrapper .e-split-btn {
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper .e-split-btn:focus {
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper .e-split-btn:active {
  border-right-color: #e5e7eb;
}
.e-split-btn-wrapper .e-split-btn:disabled {
  border-right-color: #fff;
}
.e-split-btn-wrapper .e-split-btn.e-inherit {
  border-color: transparent;
}
.e-split-btn-wrapper:hover :not(e-active) .e-split-btn {
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper:hover :not(e-active) .e-split-btn:disabled {
  border-right-color: #e5e7eb;
}
.e-split-btn-wrapper.e-rtl .e-split-btn {
  border-left-color: #d1d5db;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-rtl .e-split-btn:focus {
  border-left-color: #d1d5db;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-rtl .e-split-btn:active {
  border-left-color: #e5e7eb;
  border-right-color: #e5e7eb;
}
.e-split-btn-wrapper.e-rtl .e-split-btn:disabled {
  border-left-color: #e5e7eb;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-rtl .e-split-btn.e-inherit {
  border-color: transparent;
}
.e-split-btn-wrapper.e-rtl:hover .e-split-btn :not(e-active) {
  border-left-color: #d1d5db;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-rtl:hover .e-split-btn :not(e-active):disabled {
  border-left-color: #e5e7eb;
}
.e-split-btn-wrapper.e-vertical .e-split-btn {
  border-bottom-color: #d1d5db;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit, .e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit:focus, .e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit:active, .e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit:disabled {
  border-color: transparent;
}
.e-split-btn-wrapper.e-vertical .e-split-btn:focus {
  border-bottom-color: #d1d5db;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical .e-split-btn:active {
  border-bottom-color: #e5e7eb;
  border-right-color: #e5e7eb;
}
.e-split-btn-wrapper.e-vertical .e-split-btn:disabled {
  border-bottom-color: #e5e7eb;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical:hover .e-split-btn {
  border-bottom-color: #d1d5db;
  border-right-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical:hover .e-split-btn.e-inherit, .e-split-btn-wrapper.e-vertical:hover .e-split-btn.e-inherit:disabled {
  border-color: transparent;
}
.e-split-btn-wrapper.e-vertical:hover .e-split-btn:disabled {
  border-bottom-color: #e5e7eb;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn {
  border-left-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit, .e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit:focus, .e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit:active, .e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit:disabled {
  border-color: transparent;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn:focus {
  border-left-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn:active {
  border-left-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn:disabled {
  border-left-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical.e-rtl:hover .e-split-btn {
  border-left-color: #d1d5db;
}
.e-split-btn-wrapper.e-vertical.e-rtl:hover .e-split-btn.e-inherit {
  border-color: transparent;
}

/* stylelint-disable */
*.e-btn-group,
*.e-css.e-btn-group {
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 4px;
  -ms-flex-direction: row;
      flex-direction: row;
  position: relative;
}
*.e-btn-group input:focus + label.e-btn,
*.e-btn-group .e-btn:focus,
*.e-btn-group .e-btn:hover,
*.e-css.e-btn-group input:focus + label.e-btn,
*.e-css.e-btn-group .e-btn:focus,
*.e-css.e-btn-group .e-btn:hover {
  z-index: 2;
}
*.e-btn-group input + label.e-btn,
*.e-css.e-btn-group input + label.e-btn {
  margin-bottom: 0;
}
*.e-btn-group input,
*.e-css.e-btn-group input {
  -moz-appearance: none;
  height: 1px;
  margin: 0 0 0 -1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
*.e-btn-group input:disabled + label.e-btn,
*.e-btn-group :disabled,
*.e-css.e-btn-group input:disabled + label.e-btn,
*.e-css.e-btn-group :disabled {
  cursor: default;
  pointer-events: none;
}
*.e-btn-group .e-btn-icon,
*.e-css.e-btn-group .e-btn-icon {
  font-size: 18px;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn {
  margin-left: -1px;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:not(:first-of-type):not(:last-of-type),
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:first-of-type,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:first-of-type {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper + .e-btn,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper + .e-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:first-child .e-btn:last-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:first-child .e-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:last-child .e-btn:first-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:last-child .e-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-btn,
*.e-css.e-btn-group.e-rtl .e-btn {
  margin-left: -1px;
}
*.e-btn-group.e-rtl .e-btn:not(:first-of-type):not(:last-of-type),
*.e-css.e-btn-group.e-rtl .e-btn:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
*.e-btn-group.e-rtl .e-btn:first-of-type,
*.e-css.e-btn-group.e-rtl .e-btn:first-of-type {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-btn:last-of-type,
*.e-css.e-btn-group.e-rtl .e-btn:last-of-type {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-rtl .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group.e-rtl .e-btn:last-of-type:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper + .e-btn,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper + .e-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:first-child .e-btn:last-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:first-child .e-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:last-child .e-btn:first-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:last-child .e-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-vertical,
*.e-css.e-btn-group.e-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
}
*.e-btn-group.e-vertical input,
*.e-css.e-btn-group.e-vertical input {
  margin: -1px 0 0;
}
*.e-btn-group.e-vertical .e-btn:not(.e-outline),
*.e-css.e-btn-group.e-vertical .e-btn:not(.e-outline) {
  margin-top: -1px;
}
*.e-btn-group.e-vertical .e-btn:not(:first-of-type):not(:last-of-type),
*.e-css.e-btn-group.e-vertical .e-btn:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
*.e-btn-group.e-vertical .e-btn:first-of-type,
*.e-css.e-btn-group.e-vertical .e-btn:first-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
*.e-btn-group.e-vertical .e-btn:last-of-type,
*.e-css.e-btn-group.e-vertical .e-btn:last-of-type {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) {
  border-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:first-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:first-of-type {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper + .e-btn,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper + .e-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:first-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:first-child {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:last-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:first-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:last-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:last-child {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl {
  border-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type:not(:first-child),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.e-bigger .e-btn-group .e-btn-icon,
.e-bigger.e-btn-group .e-btn-icon,
.e-bigger .e-css.e-btn-group .e-btn-icon,
.e-bigger.e-css.e-btn-group .e-btn-icon {
  font-size: 22px;
}

/* stylelint-disable */
.e-btn-group:not(.e-outline),
.e-css.e-btn-group:not(.e-outline) {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.e-btn-group .e-btn:hover,
.e-css.e-btn-group .e-btn:hover {
  box-shadow: none;
}
.e-btn-group .e-btn:focus,
.e-btn-group input:focus-visible + label.e-btn,
.e-btn-group input:focus + label.e-btn,
.e-css.e-btn-group .e-btn:focus,
.e-css.e-btn-group input:focus-visible + label.e-btn,
.e-css.e-btn-group input:focus + label.e-btn {
  background: #f3f4f6;
  border-color: #d1d5db;
  color: #374151;
  outline: #fff 0 solid;
  outline-offset: 0;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-primary,
.e-btn-group input:focus-visible + label.e-btn.e-primary,
.e-btn-group input:focus + label.e-btn.e-primary,
.e-css.e-btn-group .e-btn:focus.e-primary,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-primary,
.e-css.e-btn-group input:focus + label.e-btn.e-primary {
  background: #4338ca;
  border-color: #4f46e5;
  color: #fff;
  outline: #fff 0 solid;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-success,
.e-btn-group input:focus-visible + label.e-btn.e-success,
.e-btn-group input:focus + label.e-btn.e-success,
.e-css.e-btn-group .e-btn:focus.e-success,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-success,
.e-css.e-btn-group input:focus + label.e-btn.e-success {
  background: #166534;
  border-color: #15803d;
  color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-info,
.e-btn-group input:focus-visible + label.e-btn.e-info,
.e-btn-group input:focus + label.e-btn.e-info,
.e-css.e-btn-group .e-btn:focus.e-info,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-info,
.e-css.e-btn-group input:focus + label.e-btn.e-info {
  background: #155e75;
  border-color: #0e7490;
  color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-warning,
.e-btn-group input:focus-visible + label.e-btn.e-warning,
.e-btn-group input:focus + label.e-btn.e-warning,
.e-css.e-btn-group .e-btn:focus.e-warning,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-warning,
.e-css.e-btn-group input:focus + label.e-btn.e-warning {
  background: #9a3412;
  border-color: #c2410c;
  color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-danger,
.e-btn-group input:focus-visible + label.e-btn.e-danger,
.e-btn-group input:focus + label.e-btn.e-danger,
.e-css.e-btn-group .e-btn:focus.e-danger,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-danger,
.e-css.e-btn-group input:focus + label.e-btn.e-danger {
  background: #b91c1c;
  border-color: #dc2626;
  color: #fff;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-link,
.e-btn-group input:focus-visible + label.e-btn.e-link,
.e-btn-group input:focus + label.e-btn.e-link,
.e-css.e-btn-group .e-btn:focus.e-link,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-link,
.e-css.e-btn-group input:focus + label.e-btn.e-link {
  border-radius: 0;
  text-decoration: underline;
  color: #3730a3;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none;
}
.e-btn-group .e-btn:focus.e-outline,
.e-btn-group input:focus-visible + label.e-btn.e-outline,
.e-btn-group input:focus + label.e-btn.e-outline,
.e-css.e-btn-group .e-btn:focus.e-outline,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline,
.e-css.e-btn-group input:focus + label.e-btn.e-outline {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
  box-shadow: none;
}
.e-btn-group .e-btn:focus.e-outline.e-primary,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-primary,
.e-btn-group input:focus + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group .e-btn:focus.e-outline.e-primary,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-primary {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
}
.e-btn-group .e-btn:focus.e-outline.e-success,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-success,
.e-btn-group input:focus + label.e-btn.e-outline.e-success,
.e-css.e-btn-group .e-btn:focus.e-outline.e-success,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-success,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-success {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
}
.e-btn-group .e-btn:focus.e-outline.e-info,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-info,
.e-btn-group input:focus + label.e-btn.e-outline.e-info,
.e-css.e-btn-group .e-btn:focus.e-outline.e-info,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-info,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-info {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
}
.e-btn-group .e-btn:focus.e-outline.e-warning,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-warning,
.e-btn-group input:focus + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group .e-btn:focus.e-outline.e-warning,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-warning {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
}
.e-btn-group .e-btn:focus.e-outline.e-danger,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-danger,
.e-btn-group input:focus + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group .e-btn:focus.e-outline.e-danger,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-danger {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95), 0 0 0 4px #4f46e5;
}
.e-btn-group .e-btn:active,
.e-btn-group input:active + label.e-btn,
.e-btn-group input:checked + label.e-btn,
.e-css.e-btn-group .e-btn:active,
.e-css.e-btn-group input:active + label.e-btn,
.e-css.e-btn-group input:checked + label.e-btn {
  background: #e5e7eb;
  border-color: #d1d5db;
  color: #374151;
  outline: #fff 0 solid;
  outline-offset: 0;
}
.e-btn-group .e-btn:active.e-primary,
.e-btn-group input:active + label.e-btn.e-primary,
.e-btn-group input:checked + label.e-btn.e-primary,
.e-css.e-btn-group .e-btn:active.e-primary,
.e-css.e-btn-group input:active + label.e-btn.e-primary,
.e-css.e-btn-group input:checked + label.e-btn.e-primary {
  background: #3730a3;
  border-color: #3730a3;
  color: #fff;
  outline: #fff 0 solid;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.e-btn-group .e-btn:active.e-success,
.e-btn-group input:active + label.e-btn.e-success,
.e-btn-group input:checked + label.e-btn.e-success,
.e-css.e-btn-group .e-btn:active.e-success,
.e-css.e-btn-group input:active + label.e-btn.e-success,
.e-css.e-btn-group input:checked + label.e-btn.e-success {
  background: #14532d;
  border-color: #14532d;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.e-btn-group .e-btn:active.e-info,
.e-btn-group input:active + label.e-btn.e-info,
.e-btn-group input:checked + label.e-btn.e-info,
.e-css.e-btn-group .e-btn:active.e-info,
.e-css.e-btn-group input:active + label.e-btn.e-info,
.e-css.e-btn-group input:checked + label.e-btn.e-info {
  background: #164e63;
  color: #fff;
  border-color: #164e63;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.e-btn-group .e-btn:active.e-warning,
.e-btn-group input:active + label.e-btn.e-warning,
.e-btn-group input:checked + label.e-btn.e-warning,
.e-css.e-btn-group .e-btn:active.e-warning,
.e-css.e-btn-group input:active + label.e-btn.e-warning,
.e-css.e-btn-group input:checked + label.e-btn.e-warning {
  background: #7c2d12;
  border-color: #7c2d12;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.e-btn-group .e-btn:active.e-danger,
.e-btn-group input:active + label.e-btn.e-danger,
.e-btn-group input:checked + label.e-btn.e-danger,
.e-css.e-btn-group .e-btn:active.e-danger,
.e-css.e-btn-group input:active + label.e-btn.e-danger,
.e-css.e-btn-group input:checked + label.e-btn.e-danger {
  background: #991b1b;
  border-color: #991b1b;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.e-btn-group .e-btn:active.e-outline,
.e-btn-group input:active + label.e-btn.e-outline,
.e-btn-group input:checked + label.e-btn.e-outline,
.e-css.e-btn-group .e-btn:active.e-outline,
.e-css.e-btn-group input:active + label.e-btn.e-outline,
.e-css.e-btn-group input:checked + label.e-btn.e-outline {
  background: #e5e7eb;
  border-color: #d1d5db;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #374151;
}
.e-btn-group .e-btn:active.e-outline.e-primary,
.e-btn-group input:active + label.e-btn.e-outline.e-primary,
.e-btn-group input:checked + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group .e-btn:active.e-outline.e-primary,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-primary {
  background: #3730a3;
  border-color: #4f46e5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #fff;
}
.e-btn-group .e-btn:active.e-outline.e-success,
.e-btn-group input:active + label.e-btn.e-outline.e-success,
.e-btn-group input:checked + label.e-btn.e-outline.e-success,
.e-css.e-btn-group .e-btn:active.e-outline.e-success,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-success,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-success {
  background: #14532d;
  border-color: #14532d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #fff;
}
.e-btn-group .e-btn:active.e-outline.e-info,
.e-btn-group input:active + label.e-btn.e-outline.e-info,
.e-btn-group input:checked + label.e-btn.e-outline.e-info,
.e-css.e-btn-group .e-btn:active.e-outline.e-info,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-info,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-info {
  background: #164e63;
  border-color: #164e63;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #fff;
}
.e-btn-group .e-btn:active.e-outline.e-warning,
.e-btn-group input:active + label.e-btn.e-outline.e-warning,
.e-btn-group input:checked + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group .e-btn:active.e-outline.e-warning,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-warning {
  background: #7c2d12;
  border-color: #7c2d12;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #fff;
}
.e-btn-group .e-btn:active.e-outline.e-danger,
.e-btn-group input:active + label.e-btn.e-outline.e-danger,
.e-btn-group input:checked + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group .e-btn:active.e-outline.e-danger,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-danger {
  background: #991b1b;
  border-color: #991b1b;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #fff;
}
.e-btn-group .e-btn:disabled,
.e-btn-group input:disabled + label.e-btn,
.e-css.e-btn-group .e-btn:disabled,
.e-css.e-btn-group input:disabled + label.e-btn {
  background-color: #fff;
  border-color: #e5e7eb;
  box-shadow: none;
  color: #9ca3af;
  border-color: #d1d5db;
}
.e-btn-group .e-btn:disabled.e-primary,
.e-btn-group input:disabled + label.e-btn.e-primary,
.e-css.e-btn-group .e-btn:disabled.e-primary,
.e-css.e-btn-group input:disabled + label.e-btn.e-primary {
  background: #a5b4fc;
  border-color: #a5b4fc;
  box-shadow: none;
  color: #fff;
  border-color: #4f46e5;
}
.e-btn-group .e-btn:disabled.e-success,
.e-btn-group input:disabled + label.e-btn.e-success,
.e-css.e-btn-group .e-btn:disabled.e-success,
.e-css.e-btn-group input:disabled + label.e-btn.e-success {
  background: #86efac;
  border-color: #86efac;
  box-shadow: none;
  color: #fff;
  border-color: #15803d;
}
.e-btn-group .e-btn:disabled.e-info,
.e-btn-group input:disabled + label.e-btn.e-info,
.e-css.e-btn-group .e-btn:disabled.e-info,
.e-css.e-btn-group input:disabled + label.e-btn.e-info {
  background: #67e8f9;
  border-color: #67e8f9;
  box-shadow: none;
  color: #fff;
  border-color: #0e7490;
}
.e-btn-group .e-btn:disabled.e-warning,
.e-btn-group input:disabled + label.e-btn.e-warning,
.e-css.e-btn-group .e-btn:disabled.e-warning,
.e-css.e-btn-group input:disabled + label.e-btn.e-warning {
  background: #fdba74;
  border-color: #fdba74;
  box-shadow: none;
  color: #fff;
  border-color: #c2410c;
}
.e-btn-group .e-btn:disabled.e-danger,
.e-btn-group input:disabled + label.e-btn.e-danger,
.e-css.e-btn-group .e-btn:disabled.e-danger,
.e-css.e-btn-group input:disabled + label.e-btn.e-danger {
  background: #fca5a5;
  border-color: #fca5a5;
  box-shadow: none;
  color: #fff;
  border-color: #dc2626;
}
.e-btn-group .e-btn:disabled.e-link,
.e-btn-group input:disabled + label.e-btn.e-link,
.e-css.e-btn-group .e-btn:disabled.e-link,
.e-css.e-btn-group input:disabled + label.e-btn.e-link {
  color: #9ca3af;
  background: transparent;
  box-shadow: none;
  text-decoration: none;
  border-color: transparent;
}
.e-btn-group .e-btn:disabled.e-outline,
.e-btn-group input:disabled + label.e-btn.e-outline,
.e-css.e-btn-group .e-btn:disabled.e-outline,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline {
  background: transparent;
  border-color: #e5e7eb;
  color: #9ca3af;
  border-color: #d1d5db;
}
.e-btn-group .e-btn:disabled.e-outline.e-primary,
.e-btn-group input:disabled + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-primary,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-primary {
  background: transparent;
  border-color: rgba(79, 70, 229, 0.65);
  box-shadow: none;
  color: rgba(79, 70, 229, 0.65);
  border-color: #4f46e5;
}
.e-btn-group .e-btn:disabled.e-outline.e-success,
.e-btn-group input:disabled + label.e-btn.e-outline.e-success,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-success,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-success {
  background: transparent;
  border-color: rgba(21, 128, 61, 0.65);
  box-shadow: none;
  color: rgba(21, 128, 61, 0.65);
  border-color: #15803d;
}
.e-btn-group .e-btn:disabled.e-outline.e-info,
.e-btn-group input:disabled + label.e-btn.e-outline.e-info,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-info,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-info {
  background: transparent;
  border-color: rgba(14, 116, 144, 0.65);
  box-shadow: none;
  color: rgba(14, 116, 144, 0.65);
  border-color: #0e7490;
}
.e-btn-group .e-btn:disabled.e-outline.e-warning,
.e-btn-group input:disabled + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-warning,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-warning {
  background: transparent;
  border-color: rgba(194, 65, 12, 0.65);
  box-shadow: none;
  color: rgba(194, 65, 12, 0.65);
  border-color: #c2410c;
}
.e-btn-group .e-btn:disabled.e-outline.e-danger,
.e-btn-group input:disabled + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-danger,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-danger {
  background: transparent;
  border-color: rgba(220, 38, 38, 0.65);
  box-shadow: none;
  color: rgba(220, 38, 38, 0.65);
  border-color: #dc2626;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type),
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type) {
  border-left: transparent;
  border-right: transparent;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:first-of-type,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:first-of-type {
  border-right: transparent;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:last-of-type,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:last-of-type {
  border-left: transparent;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline {
  border-color: #d1d5db;
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary {
  border-color: #4f46e5;
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-success,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-success {
  border-color: #15803d;
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-info,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-info {
  border-color: #0e7490;
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning {
  border-color: #c2410c;
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger {
  border-color: #dc2626;
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type),
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type) {
  border-bottom: transparent;
  border-top: transparent;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-outline:first-of-type,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-outline:first-of-type {
  border-bottom: transparent;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-outline:last-of-type,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-outline:last-of-type {
  border-top: transparent;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline {
  border-color: #d1d5db;
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary {
  border-color: #4f46e5;
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-success,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-success {
  border-color: #15803d;
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-info,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-info {
  border-color: #0e7490;
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning {
  border-color: #c2410c;
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger {
  border-color: #dc2626;
  outline-color: inherit;
}
/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*! Progress Button Layout */
.e-progress-btn {
  position: relative;
}
.e-progress-btn .e-progress {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s linear;
}
.e-progress-btn .e-spinner {
  transition: all 0.3s linear;
}
.e-progress-btn .e-btn-content {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.e-progress-btn.e-vertical .e-progress {
  bottom: 0;
  height: 0%;
  top: auto;
  width: 100%;
}
.e-progress-btn.e-progress-top .e-progress {
  height: 10%;
}
.e-progress-btn.e-progress-bottom .e-progress {
  bottom: -1px;
  height: 10%;
  top: auto;
}
.e-progress-btn.e-progress-active {
  overflow: hidden;
  pointer-events: none;
}
.e-progress-btn.e-progress-active .e-progress {
  opacity: 1;
}
.e-progress-btn.e-progress-active .e-animate-end {
  opacity: 0;
}
.e-progress-btn.e-spin-top, .e-progress-btn.e-spin-bottom {
  transition: padding 0.3s linear;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-btn-content,
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner, .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-btn-content,
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner {
  display: block;
  line-height: 1;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-center .e-spinner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner {
  padding-right: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner .e-spinner-inner {
  top: -1px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 12px;
  padding-right: 0;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner {
  padding-left: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner .e-spinner-inner {
  top: -1px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top {
  padding-bottom: 16px;
  padding-top: 16px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner {
  padding-bottom: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom {
  padding-bottom: 16px;
  padding-top: 16px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner {
  padding-top: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round) .e-spinner {
  padding-right: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 10px;
  padding-right: 0;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round) .e-spinner {
  padding-left: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top {
  padding-bottom: 14px;
  padding-top: 14px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top .e-spinner {
  padding-bottom: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom {
  padding-bottom: 14px;
  padding-top: 14px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom .e-spinner {
  padding-top: 10px;
}
.e-progress-btn.e-round-corner {
  border-radius: 5em;
  outline: none;
  overflow: hidden;
}
.e-progress-btn.e-rtl .e-progress {
  left: auto;
  right: 0;
}
.e-progress-btn.e-rtl.e-vertical .e-progress {
  bottom: auto;
  top: 0;
}
.e-progress-btn .e-spinner-pane {
  height: auto;
  position: relative;
  width: auto;
}
.e-progress-btn .e-spinner-pane .e-spinner-inner {
  left: auto;
  position: relative;
  top: auto;
  transform: none;
}
.e-progress-btn .e-cont-animate {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@keyframes ProgressSlideLeft {
  from {
    left: 0%;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0;
  }
}
@keyframes ProgressSlideRight {
  from {
    left: 0%;
    opacity: 1;
  }
  to {
    left: 100%;
    opacity: 0;
  }
}
@keyframes ProgressSlideUp {
  from {
    height: auto;
    opacity: 1;
    top: 0;
  }
  to {
    height: auto;
    opacity: 0;
    top: -100px;
  }
}
@keyframes ProgressSlideDown {
  from {
    height: auto;
    opacity: 1;
    top: 0;
  }
  to {
    height: auto;
    opacity: 0;
    top: 100px;
  }
}
@keyframes ProgressZoomIn {
  from {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(0, 0) scale(3);
  }
}
@keyframes ProgressZoomOut {
  from {
    transform: translate(0, 0) scale(1);
  }
  to {
    transform: translate(0, 0) scale(0);
  }
}

*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner {
  padding-right: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 14px;
  padding-right: 0;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner {
  padding-left: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top {
  padding-bottom: 20px;
  padding-top: 20px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner {
  padding-bottom: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom {
  padding-bottom: 20px;
  padding-top: 20px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner {
  padding-top: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round) .e-spinner {
  padding-right: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 12px;
  padding-right: 0;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round) .e-spinner {
  padding-left: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top {
  padding-bottom: 16px;
  padding-top: 16px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top .e-spinner {
  padding-bottom: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom {
  padding-bottom: 16px;
  padding-top: 16px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom .e-spinner {
  padding-top: 12px;
}

/*! Progress Button Theme */
.e-progress-btn .e-spinner-pane .e-spinner-inner svg {
  fill: #374151;
  stroke: #374151;
}
.e-progress-btn .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke-width: 3px;
}
.e-progress-btn .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #374151;
  stroke-width: 3px;
}
.e-progress-btn.e-primary .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: #fff;
  stroke: #fff;
}
.e-progress-btn.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-primary.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: #4f46e5;
  stroke: #4f46e5;
}
.e-progress-btn.e-primary.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-primary.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-primary.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-success .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: #fff;
  stroke: #fff;
}
.e-progress-btn.e-success .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-success.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: #15803d;
  stroke: #15803d;
}
.e-progress-btn.e-success.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-success.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-success.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-info .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: #fff;
  stroke: #fff;
}
.e-progress-btn.e-info .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-info .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-info.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: #0e7490;
  stroke: #0e7490;
}
.e-progress-btn.e-info.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-info.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-info.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-warning .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: #fff;
  stroke: #fff;
}
.e-progress-btn.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-warning.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: #c2410c;
  stroke: #c2410c;
}
.e-progress-btn.e-warning.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-warning.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-danger .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: #fff;
  stroke: #fff;
}
.e-progress-btn.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-danger.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: #dc2626;
  stroke: #dc2626;
}
.e-progress-btn.e-danger.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-danger.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(255, 255, 255, 0.2);
}
.e-progress-btn.e-danger.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #fff;
}
.e-progress-btn.e-outline.e-primary .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-primary .e-spinner-pane .e-spinner-inner svg {
  fill: #4f46e5;
  stroke: #4f46e5;
}
.e-progress-btn.e-outline.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-flat.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(79, 70, 229, 0.2);
}
.e-progress-btn.e-outline.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #4f46e5;
}
.e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-success .e-spinner-pane .e-spinner-inner svg {
  fill: #15803d;
  stroke: #15803d;
}
.e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-flat.e-success .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(21, 128, 61, 0.2);
}
.e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #15803d;
}
.e-progress-btn.e-outline.e-info .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-info .e-spinner-pane .e-spinner-inner svg {
  fill: #0e7490;
  stroke: #0e7490;
}
.e-progress-btn.e-outline.e-info .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-flat.e-info .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(14, 116, 144, 0.2);
}
.e-progress-btn.e-outline.e-info .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-info .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #0e7490;
}
.e-progress-btn.e-outline.e-warning .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-warning .e-spinner-pane .e-spinner-inner svg {
  fill: #c2410c;
  stroke: #c2410c;
}
.e-progress-btn.e-outline.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-flat.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(194, 65, 12, 0.2);
}
.e-progress-btn.e-outline.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #c2410c;
}
.e-progress-btn.e-outline.e-danger .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-danger .e-spinner-pane .e-spinner-inner svg {
  fill: #dc2626;
  stroke: #dc2626;
}
.e-progress-btn.e-outline.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-flat.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(220, 38, 38, 0.2);
}
.e-progress-btn.e-outline.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #dc2626;
}
.e-progress-btn.e-link .e-spinner-pane .e-spinner-inner svg {
  fill: #4f46e5;
  stroke: #4f46e5;
}
.e-progress-btn.e-link .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(79, 70, 229, 0.2);
}
.e-progress-btn.e-link .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #4f46e5;
}

.e-progress {
  background: rgba(107, 114, 128, 0.2);
}

*.e-primary .e-progress,
*.e-success .e-progress,
*.e-danger .e-progress,
*.e-info .e-progress {
  background: rgba(107, 114, 128, 0.2);
}

*.e-flat.e-primary .e-progress {
  background: rgba(255, 255, 255, 0.2);
}
*.e-flat.e-success .e-progress {
  background: rgba(255, 255, 255, 0.2);
}
*.e-flat.e-info .e-progress {
  background: rgba(255, 255, 255, 0.2);
}
*.e-flat.e-warning .e-progress {
  background: rgba(255, 255, 255, 0.2);
}
*.e-flat.e-danger .e-progress {
  background: rgba(255, 255, 255, 0.2);
}

*.e-outline:not(:focus).e-primary .e-progress {
  background: rgba(79, 70, 229, 0.2);
}
*.e-outline:not(:focus).e-success .e-progress {
  background: rgba(21, 128, 61, 0.2);
}
*.e-outline:not(:focus).e-info .e-progress {
  background: rgba(14, 116, 144, 0.2);
}
*.e-outline:not(:focus).e-warning .e-progress {
  background: rgba(194, 65, 12, 0.2);
}
*.e-outline:not(:focus).e-danger .e-progress {
  background: rgba(220, 38, 38, 0.2);
}

*.e-flat:not(:focus).e-primary .e-progress {
  background: rgba(79, 70, 229, 0.2);
}
*.e-flat:not(:focus).e-success .e-progress {
  background: rgba(21, 128, 61, 0.2);
}
*.e-flat:not(:focus).e-info .e-progress {
  background: rgba(14, 116, 144, 0.2);
}
*.e-flat:not(:focus).e-warning .e-progress {
  background: rgba(194, 65, 12, 0.2);
}
*.e-flat:not(:focus).e-danger .e-progress {
  background: rgba(220, 38, 38, 0.2);
}

.e-progress-btn.e-outline .e-spinner-pane .e-spinner-inner svg {
  fill: #374151;
  stroke: #374151;
}

.e-progress-btn.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
  fill: #374151;
  stroke: #374151;
}

.e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: #374151;
}