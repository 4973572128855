.e-ddl.e-control-wrapper .e-ddl-icon::before {
  transform: rotate(0deg);
  transition: transform 300ms ease;
}

.e-ddl.e-control-wrapper.e-icon-anim .e-ddl-icon::before {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.e-dropdownbase .e-list-item.e-active.e-hover {
  color: #111827;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:active,
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:hover,
.e-input-group:not(.e-disabled) .e-back-icon:active,
.e-input-group:not(.e-disabled) .e-back-icon:hover,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:active,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:hover {
  background: transparent;
}

.e-input-group .e-ddl-icon:not(:active)::after {
  animation: none;
}

.e-ddl.e-popup {
  border: 0;
  border-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-top: 4px;
}

.e-small .e-ddl.e-popup,
.e-small.e-ddl.e-popup {
  border-radius: 2px;
  margin-top: 2px;
}

.e-bigger .e-ddl.e-popup,
.e-bigger.e-ddl.e-popup {
  border-radius: 6px;
  margin-top: 8px;
}

.e-bigger.e-small .e-ddl.e-popup,
.e-small.e-bigger.e-ddl.e-popup {
  border-radius: 4px;
  margin-top: 6px;
}

.e-popup.e-ddl .e-dropdownbase {
  min-height: 26px;
  padding: 4px 0;
}

.e-bigger .e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #e5e7eb;
}

.e-bigger .e-popup.e-ddl-device-filter {
  margin-top: 0;
}

.e-bigger .e-ddl-device .e-input-group,
.e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  background: #fff;
  border-width: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.e-bigger .e-ddl-device .e-input-group .e-back-icon,
.e-bigger .e-ddl-device .e-input-group input.e-input,
.e-bigger .e-ddl-device .e-input-group .e-clear-icon {
  background-color: #fff;
}

.e-input-group.e-ddl,
.e-input-group.e-ddl .e-input,
.e-input-group.e-ddl .e-ddl-icon {
  background: #fff;
}

.e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group:hover:not(.e-disabled):not(.e-float-icon-left),
.e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group.e-control-wrapper:hover:not(.e-disabled):not(.e-float-icon-left) {
  border-bottom-width: 0;
}

.e-ddl.e-popup.e-outline .e-filter-parent {
  padding: 4px 8px;
}

.e-multi-select-wrapper .e-chips-collection .e-chips .e-chips-close.e-icon::before {
  line-height: 30px;
  top: 0;
}

.e-multiselect .e-input-group-icon.e-ddl-icon {
  border-radius: 0 4px 4px 0;
  border-right-width: 0;
}

.e-multiselect.e-rtl .e-input-group-icon.e-ddl-icon {
  border-left-width: 0;
  border-radius: 4px 0 0 4px;
  border-right-width: 0;
}

.e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  height: 32px;
  width: 32px;
}

.e-bigger .e-multiselect.e-control-container .e-multi-select-wrapper .e-clear-icon {
  height: 28px;
  width: 28px;
}

.e-multi-select-wrapper .e-searcher input[type=text],
.e-multi-select-wrapper .e-multi-searcher input[type=text] {
  color: #111827;
  height: 100%;
}

/* stylelint-disable-line no-empty-source */ /*! TreeView's tailwind theme wise override definitions and variables */
/* stylelint-disable */
/* stylelint-disable-line no-empty-source */
/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.e-kanban .e-column-expand::before {
  content: "\e765";
}
.e-kanban .e-column-collapse::before {
  content: "\e748";
}
.e-kanban .e-swimlane-row-expand::before {
  content: "\e729";
}
.e-kanban .e-swimlane-row-collapse::before {
  content: "\e748";
}
.e-kanban .e-show-add-icon::before {
  content: "\e805";
}
.e-kanban .e-swimlane-header-toolbar .e-icon-menu::before {
  content: "\e799";
}
.e-kanban.e-rtl .e-column-collapse::before {
  content: "\e765";
}
.e-kanban.e-rtl .e-column-expand::before {
  content: "\e748";
}
.e-kanban.e-rtl .e-swimlane-row-collapse::before {
  content: "\e765";
}

.e-mobile-popup-wrapper .e-close-icon::before {
  content: "\e7e7";
}

/*! kanban component layout */
.e-kanban {
  display: block;
  outline: medium none;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.e-kanban .e-kanban-table {
  border: 0;
  border-collapse: separate;
  border-spacing: 8px 0;
  table-layout: fixed;
  width: 100%;
}
.e-kanban .e-kanban-table col.e-collapsed {
  width: 42px;
}
.e-kanban .e-swimlane .e-kanban-table.e-content-table {
  border-spacing: 8px 0;
}
.e-kanban .e-frozen-swimlane-row {
  position: relative;
  z-index: 101;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row {
  padding: 12px 12px 12px 20px;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header {
  display: -ms-flexbox;
  display: flex;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  padding: 0 0 0 8px;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-item-count {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.5;
  opacity: 1;
  padding: 3px;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-expand,
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-collapse {
  border: 1px solid transparent;
  color: #6b7280;
  cursor: pointer;
  font-size: 18px;
  height: 20px;
  margin: 1px;
  padding: 0;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-expand:hover, .e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-expand:focus,
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-collapse:hover,
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-collapse:focus {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #4f46e5;
  color: #4f46e5;
  margin: 1px;
  padding: 0;
}
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-expand:focus,
.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-row-collapse:focus {
  background: transparent;
  box-shadow: 0 0 0 1px #4f46e5;
}
.e-kanban .e-kanban-header > div {
  overflow: hidden;
}
.e-kanban .e-kanban-header .e-header-cells {
  border-radius: 4px;
  height: 42px;
  overflow: hidden;
  padding: 12px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-wrap {
  display: -ms-flexbox;
  display: flex;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-wrap .e-header-title {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.e-kanban .e-kanban-header .e-header-cells.e-stacked-header-cell {
  border-bottom: 1px solid #d1d5db;
}
.e-kanban .e-kanban-header .e-header-cells.e-toggle-header .e-column-expand {
  cursor: pointer;
  font-size: 18px;
  padding: 0;
}
.e-kanban .e-kanban-header .e-header-cells.e-toggle-header .e-column-collapse {
  cursor: pointer;
  font-size: 18px;
  padding: 0;
}
.e-kanban .e-kanban-header .e-header-cells.e-min-color {
  background: #ffedd5;
}
.e-kanban .e-kanban-header .e-header-cells.e-max-color {
  background: #fee2e2;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed {
  background: #f3f4f6;
  text-align: center;
  width: 42px;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed .e-header-wrap {
  -ms-flex-pack: center;
      justify-content: center;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed.e-min-color {
  background: #ffedd5;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed.e-max-color {
  background: #fee2e2;
}
.e-kanban .e-kanban-header .e-header-cells.e-collapsed .e-limits,
.e-kanban .e-kanban-header .e-header-cells.e-collapsed .e-header-title {
  display: none;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  overflow: hidden;
  padding-right: 5px;
  text-overflow: ellipsis;
}
.e-kanban .e-kanban-header .e-header-cells .e-item-count {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.625;
  margin-top: 2px;
  opacity: 1;
  text-transform: uppercase;
}
.e-kanban .e-kanban-header .e-header-cells .e-limits {
  display: -ms-flexbox;
  display: flex;
  padding-top: 3px;
}
.e-kanban .e-kanban-header .e-header-cells .e-limits .e-min-count,
.e-kanban .e-kanban-header .e-header-cells .e-limits .e-max-count {
  font-size: 10px;
  font-weight: 500;
  opacity: 1;
  width: 100%;
}
.e-kanban .e-kanban-header .e-header-cells .e-limits .e-max-count {
  text-align: right;
}
.e-kanban .e-kanban-content {
  overflow: auto;
  padding-bottom: 10px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-limits {
  display: -ms-flexbox;
  display: flex;
  height: 26px;
  position: relative;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-kanban-border {
  display: none;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-kanban-border.e-dropping {
  display: block;
  position: absolute;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-limits .e-min-count,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-limits .e-max-count {
  font-size: 10px;
  font-weight: 500;
  opacity: 1;
  padding: 4px 8px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-limits .e-max-count {
  position: absolute;
  right: 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-min-color {
  background: #ffedd5;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-max-color {
  background: #fee2e2;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-collapsed {
  overflow: hidden;
  text-align: center;
  width: 42px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-collapsed .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-collapsed .e-card-container,
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-collapsed .e-limits {
  display: none;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-collapsed .e-collapse-header-text {
  color: #6b7280;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  padding: 12px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  -ms-writing-mode: tb-lr;
      writing-mode: vertical-lr;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells.e-collapsed .e-collapse-header-text .e-item-count {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  opacity: 0.57;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-show-add-button {
  border: 1px dashed #4f46e5;
  height: 36px;
  margin: 8px 8px 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-show-add-button .e-show-add-icon {
  padding: 11px;
  text-align: center;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-show-add-button:focus {
  border: 1px dashed #9ca3af;
  color: #9ca3af;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-multi-card-wrapper,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-multi-card-container {
  display: none;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container {
  height: inherit;
  overflow: auto;
  padding: 12px 12px 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-empty-card,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-empty-card {
  color: #6b7280;
  display: table;
  font-size: 14px;
  line-height: 1.5;
  margin: 0 auto;
  padding: 20px 0;
  position: relative;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card {
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 12px;
  min-height: 50px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-draggable,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-draggable {
  cursor: all-scroll;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-header,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-header {
  padding: 18px 18px 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-header .e-card-header-title,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-header .e-card-header-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.625;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-footer,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-footer {
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: normal;
  padding: 8px 18px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-content,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-content {
  font-size: 14px;
  line-height: 1.625;
  padding: 12px 18px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color {
  border: 1px solid transparent;
  border-left: 2px solid;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-header,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-header {
  padding: 18px 18px 0 18px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-content,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-content {
  padding: 12px 18px 8px 18px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-tags,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-tags {
  padding: 0 18px 0 18px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color.e-selection,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color.e-selection {
  border: 1px solid #e5e7eb;
  border-left: 2px solid;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color.e-selection:hover,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color.e-selection:hover {
  border: 1px solid transparent;
  border-left: 3px solid;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color:hover,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color:hover {
  border: 1px solid transparent;
  border-left: 3px solid;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-footer,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-footer {
  padding: 8px 18px 18px 18px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-tag,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-tag {
  border-radius: 2px;
  display: inline-block;
  font-size: 14px;
  line-height: 1.625;
  margin-right: 4px;
  max-width: 100%;
  overflow: hidden;
  padding: 1px 4px;
  text-overflow: ellipsis;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-tags,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-tags {
  line-height: 1;
  padding: 12px 18px 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-cloned-card,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-cloned-card {
  position: absolute;
  z-index: 1;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-selection,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-selection {
  border: 1px solid #e5e7eb;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-selection:hover,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-selection:hover {
  border: 1px solid transparent;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card:hover,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card:hover {
  border: 1px solid transparent;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card:focus,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card:focus {
  border: 1px solid transparent;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color:focus,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color:focus {
  border: 1px solid transparent;
  border-left: 2px solid;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-kanban-dragged-card,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-kanban-dragged-card {
  display: none;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-multi-card-clone,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-multi-card-clone {
  border: 1px solid #9ca3af;
  height: 34px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-multi-card-text,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-multi-card-text {
  background: #fff;
  display: table-cell;
  padding-left: 8px;
  text-align: center;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dragged-clone,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dropped-clone,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dragged-clone,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dropped-clone {
  border-radius: 6px;
  margin-bottom: 12px;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dragged-clone,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dragged-clone {
  background: #e5e7eb;
  border: 1px dashed #9ca3af;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dropped-clone,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dropped-clone {
  background: #e0e7ff;
  border: 1px dashed #4f46e5;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-target-multi-clone .e-column-key {
  border-bottom: 1px dashed #9ca3af;
  border-left: 1px dashed #9ca3af;
  border-right: 1px dashed #9ca3af;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-target-multi-clone .e-column-key .e-text {
  opacity: 0.5;
  text-align: center;
  color: #9ca3af;
  font-size: 16px;
  opacity: 1;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-target-multi-clone .e-column-key:first-child {
  border-top: 1px dashed #9ca3af;
}
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells {
  height: 46px;
  min-height: 50px;
}
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header {
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
}
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-expand,
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-collapse {
  border: 1px solid transparent;
  color: #6b7280;
  cursor: pointer;
  font-size: 18px;
  height: 20px;
  margin: 1px;
  padding: 0;
}
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-expand:hover, .e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-expand:focus,
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-collapse:hover,
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-collapse:focus {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #4f46e5;
  color: #4f46e5;
  font-size: 18px;
  margin: 1px;
  padding: 0;
}
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  padding: 0 0 0 8px;
}
.e-kanban .e-kanban-content .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-item-count {
  color: #9ca3af;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.625;
  opacity: 1;
  padding: 3px;
}
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells {
  border: 1px solid transparent;
  border-radius: 4px;
  vertical-align: top;
}
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells.e-dropping {
  border: 1px dashed #9ca3af;
}
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells.e-min-color {
  background: #ffedd5;
}
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells.e-max-color {
  background: #fee2e2;
}
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells:focus {
  border: 1px solid transparent;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells .e-card-container {
  min-height: 50px;
}
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells .e-dropping {
  border: 1px dashed #9ca3af;
}
.e-kanban .e-kanban-content .e-content-row.e-collapsed:not(.e-swimlane-row) .e-content-cells {
  border-bottom: 0;
  height: 0;
}
.e-kanban .e-kanban-content .e-content-row.e-collapsed:not(.e-swimlane-row) .e-content-cells > .e-collapse-header-text,
.e-kanban .e-kanban-content .e-content-row.e-collapsed:not(.e-swimlane-row) .e-content-cells .e-limits,
.e-kanban .e-kanban-content .e-content-row.e-collapsed:not(.e-swimlane-row) .e-content-cells .e-show-add-button,
.e-kanban .e-kanban-content .e-content-row.e-collapsed:not(.e-swimlane-row) .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row.e-collapsed:not(.e-swimlane-row) .e-content-cells .e-card-container {
  display: none;
}
.e-kanban.e-rtl .e-kanban-table .e-header-cells {
  text-align: right;
}
.e-kanban.e-rtl .e-kanban-table .e-header-cells .e-header-text {
  padding-left: 5px;
  padding-right: initial;
  text-align: right;
  text-transform: uppercase;
}
.e-kanban.e-rtl .e-kanban-table .e-header-cells .e-limits .e-max-count {
  text-align: left;
}
.e-kanban.e-rtl .e-kanban-table .e-header-cells.e-toggle-header .e-column-collapse {
  text-align: center;
}
.e-kanban.e-rtl .e-kanban-table.e-content-table .e-content-row .e-content-cells .e-limits .e-max-count {
  left: 0;
  right: auto;
}
.e-kanban.e-rtl .e-kanban-table.e-content-table .e-content-row .e-content-cells.e-collapsed .e-collapse-header-text {
  direction: initial;
}
.e-kanban.e-rtl .e-kanban-table.e-content-table .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-expand,
.e-kanban.e-rtl .e-kanban-table.e-content-table .e-content-row.e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-collapse {
  margin: 1px;
}
.e-kanban.e-device .e-swimlane-header .e-swimlane-header-toolbar {
  background: #f3f4f6;
  border-bottom: 0;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3px;
  min-height: 42px;
  padding: 10px;
}
.e-kanban.e-device .e-swimlane-header .e-swimlane-header-toolbar .e-toolbar-swimlane-name {
  color: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
}
.e-kanban.e-device .e-swimlane-header .e-swimlane-header-toolbar .e-toolbar-menu,
.e-kanban.e-device .e-swimlane-header .e-swimlane-header-toolbar .e-toolbar-level-title {
  padding-top: 6px;
}
.e-kanban.e-device .e-swimlane-header .e-swimlane-header-toolbar .e-toolbar-menu .e-icon-menu,
.e-kanban.e-device .e-swimlane-header .e-swimlane-header-toolbar .e-toolbar-level-title .e-icon-menu {
  color: inherit;
  font-size: 18px;
  padding: 7px;
  padding-left: 4px;
}
.e-kanban.e-device .e-kanban-header .e-swimlane .e-header-cells {
  border-bottom: 0;
}
.e-kanban.e-device .e-swimlane-content {
  position: absolute;
  width: 100%;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-resource {
  background: #fff;
  border: 1px solid #d1d5db;
  height: 100%;
  width: 225px;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-resource .e-swimlane-tree {
  height: 100%;
  overflow-y: auto;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-overlay {
  position: absolute;
}
.e-kanban.e-device .e-swimlane-content .e-swimlane-overlay.e-enable {
  background: rgba(107, 114, 128, 0.75);
  height: 100%;
  opacity: 1;
  width: 100%;
}

.e-bigger .e-kanban .e-swimlane-header-toolbar,
.e-bigger.e-kanban .e-swimlane-header-toolbar {
  margin-bottom: 5px;
  min-height: 56px;
}
.e-bigger .e-kanban .e-swimlane-header-toolbar .e-toolbar-swimlane-name,
.e-bigger.e-kanban .e-swimlane-header-toolbar .e-toolbar-swimlane-name {
  font-size: 18px;
}
.e-bigger .e-kanban .e-swimlane-header-toolbar .e-icon-menu,
.e-bigger.e-kanban .e-swimlane-header-toolbar .e-icon-menu {
  font-size: 20px;
}
.e-bigger .e-kanban .e-kanban-table.e-content-table,
.e-bigger.e-kanban .e-kanban-table.e-content-table {
  border-spacing: 12px 0;
}
.e-bigger .e-kanban .e-swimlane .e-kanban-table.e-content-table,
.e-bigger.e-kanban .e-swimlane .e-kanban-table.e-content-table {
  border-spacing: 12px 0;
}
.e-bigger .e-kanban .e-frozen-swimlane-row .e-frozen-row,
.e-bigger.e-kanban .e-frozen-swimlane-row .e-frozen-row {
  padding: 16px 16px 16px 28px;
}
.e-bigger .e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-text,
.e-bigger.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-swimlane-text {
  font-size: 16px;
}
.e-bigger .e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-item-count,
.e-bigger.e-kanban .e-frozen-swimlane-row .e-frozen-row .e-swimlane-header .e-item-count {
  line-height: 1.625;
  padding: 4px;
}
.e-bigger .e-kanban .e-kanban-table col.e-collapsed,
.e-bigger.e-kanban .e-kanban-table col.e-collapsed {
  width: 48px;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells,
.e-bigger.e-kanban .e-kanban-table .e-header-cells {
  height: 48px;
  padding: 16px;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-header-text,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-header-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-item-count,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-item-count {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 2px;
  text-transform: uppercase;
}
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-column-collapse,
.e-bigger .e-kanban .e-kanban-table .e-header-cells .e-column-expand,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-column-collapse,
.e-bigger.e-kanban .e-kanban-table .e-header-cells .e-column-expand {
  font-size: 20px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container {
  padding: 16px 16px 0;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dragged-clone,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dropped-clone,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dragged-clone,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dropped-clone,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dragged-clone,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-target-dropped-clone,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dragged-clone,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-target-dropped-clone {
  margin-bottom: 16px;
}
.e-bigger .e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header,
.e-bigger.e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header {
  padding: 16px;
}
.e-bigger .e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-text,
.e-bigger.e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-text {
  font-size: 16px;
  line-height: 1.5;
}
.e-bigger .e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-item-count,
.e-bigger.e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-item-count {
  font-size: 12px;
  line-height: 1.5;
  padding: 4px;
}
.e-bigger .e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-expand,
.e-bigger .e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-collapse,
.e-bigger.e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-expand,
.e-bigger.e-kanban .e-kanban-content .e-swimlane-row .e-content-cells .e-swimlane-header .e-swimlane-row-collapse {
  font-size: 14px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-header,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-header,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-header,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-header {
  padding: 24px 24px 0 24px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-content,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-content {
  padding: 8px 24px 12px 24px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-tags,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-footer,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-tags,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-footer,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-tags,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-footer,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-tags,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-footer {
  padding: 0 24px 12px 24px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-header,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-header,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-header,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-header {
  padding: 24px 24px 0;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-content,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-content {
  padding: 8px 24px 12px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-tags,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-footer,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-tags,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-footer,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-tags,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-footer,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-tags,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-footer {
  padding: 0 24px 12px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card {
  margin-bottom: 16px;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-header .e-card-header-title,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-header .e-card-header-title,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-header .e-card-header-title,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-header .e-card-header-title,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-header .e-card-header-title,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-header .e-card-header-title,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-header .e-card-header-title,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-header .e-card-header-title {
  font-size: 16px;
  line-height: 1.5;
}
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-content,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-content,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-content,
.e-bigger .e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-card-color .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-card-color .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card .e-card-content,
.e-bigger.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card .e-card-content {
  font-size: 16px;
  line-height: 1.5;
}

.e-mobile-popup-wrapper.e-device,
.e-mobile-popup-container.e-device {
  background: #fff;
  border-radius: 2px;
  bottom: 0;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  left: 0;
  margin: 0;
  max-width: 100%;
  min-width: 100%;
  opacity: 1;
  overflow: hidden;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  z-index: 1004;
}
.e-mobile-popup-wrapper.e-device .e-popup-header .e-close,
.e-mobile-popup-container.e-device .e-popup-header .e-close {
  background: transparent;
}
.e-mobile-popup-wrapper.e-device .e-popup-content,
.e-mobile-popup-container.e-device .e-popup-content {
  color: inherit;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.e-kanban-dialog.e-dialog {
  min-width: 350px;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table,
.e-kanban-dialog.e-dialog .e-kanban-form-container table {
  width: 100%;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table td,
.e-kanban-dialog.e-dialog .e-kanban-form-container table td {
  color: #374151;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5;
  opacity: 1;
  padding: 8px 0;
  text-align: left;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table td textarea.e-field,
.e-kanban-dialog.e-dialog .e-kanban-form-container table td textarea.e-field {
  resize: vertical;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table .e-label,
.e-kanban-dialog.e-dialog .e-kanban-form-container table .e-label {
  padding-right: 10px;
  vertical-align: top;
  width: 70px;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table .e-field,
.e-kanban-dialog.e-dialog .e-kanban-form-container table .e-field {
  width: 100%;
}
.e-kanban-dialog.e-dialog .e-footer-content .e-dialog-delete.e-btn {
  float: left;
  margin-left: 0;
}

*.e-rtl .e-kanban-form-wrapper table .e-label,
*.e-rtl .e-kanban-form-container table .e-label {
  padding-left: 10px;
  padding-right: 0;
  text-align: right;
}
*.e-rtl .e-dialog-delete {
  float: right;
}

.e-card-virtual-skeleton-wrapper .e-skeleton.e-skeleton-text.e-shimmer-wave {
  border-radius: 6px;
}

.e-card-virtual-skeleton-wrapper {
  height: inherit;
  overflow: hidden;
  padding: 12px 12px 0;
}

/*! kanban component theme */
.e-kanban {
  background: #fff;
}
.e-kanban .e-kanban-table .e-header-cells {
  background: #f3f4f6;
}
.e-kanban .e-kanban-table .e-header-cells .e-header-text {
  color: #6b7280;
}
.e-kanban .e-kanban-table .e-header-cells .e-item-count {
  color: #9ca3af;
}
.e-kanban .e-kanban-table .e-header-cells .e-limits {
  color: #9ca3af;
}
.e-kanban .e-kanban-table .e-header-cells .e-column-expand,
.e-kanban .e-kanban-table .e-header-cells .e-column-collapse {
  border: 1px solid transparent;
  color: #6b7280;
}
.e-kanban .e-kanban-table .e-header-cells .e-column-expand:hover, .e-kanban .e-kanban-table .e-header-cells .e-column-expand:focus,
.e-kanban .e-kanban-table .e-header-cells .e-column-collapse:hover,
.e-kanban .e-kanban-table .e-header-cells .e-column-collapse:focus {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #4f46e5;
  color: #4f46e5;
}
.e-kanban .e-kanban-table.e-content-table .e-content-row:not(.e-swimlane-row) td {
  background: #f3f4f6;
}
.e-kanban .e-kanban-table.e-content-table .e-content-row.e-swimlane-row .e-swimlane-text,
.e-kanban .e-kanban-table.e-content-table .e-content-row.e-swimlane-row .e-item-count {
  color: #111827;
}
.e-kanban .e-kanban-table.e-content-table .e-content-row .e-show-add-button:hover {
  background: #e0e7ff;
}
.e-kanban .e-kanban-table.e-content-table .e-content-row .e-show-add-button .e-show-add-icon {
  color: #4f46e5;
}
.e-kanban .e-kanban-table.e-content-table .e-card {
  background: #fff;
}
.e-kanban .e-kanban-table.e-content-table .e-card.e-selection {
  background: #f3f4f6;
}
.e-kanban .e-kanban-table.e-content-table .e-card .e-card-header {
  color: #111827;
}
.e-kanban .e-kanban-table.e-content-table .e-card .e-card-content {
  color: #374151;
}
.e-kanban .e-kanban-table.e-content-table .e-card .e-card-tag.e-card-label {
  background: #e5e7eb;
  color: #111827;
}
.e-kanban .e-kanban-table.e-content-table .e-target-multi-clone .e-column-key.e-multi-active {
  background: #e0e7ff;
  border-color: #4f46e5;
}
.e-kanban .e-kanban-table.e-content-table .e-target-multi-clone .e-column-key.e-multi-bottom-border {
  border-bottom-color: #4f46e5;
}
.e-kanban .e-kanban-table.e-content-table .e-target-multi-clone .e-column-key.e-multi-active:first-child {
  border-color: #4f46e5;
}
.e-kanban .e-frozen-swimlane-row {
  background: #fff;
}
.e-kanban .e-frozen-swimlane-row .e-swimlane-header .e-swimlane-text {
  color: #111827;
}
.e-kanban .e-frozen-swimlane-row .e-swimlane-header .e-item-count {
  color: #9ca3af;
}

.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table textarea {
  background: initial;
  color: #111827;
  min-width: 70%;
  font-weight: normal;
  border-color: #d1d5db;
  border-radius: 4px;
  outline: none;
  padding: 4px 8px 4px 12px;
}
.e-kanban-dialog.e-dialog .e-kanban-form-wrapper table textarea:focus {
  box-shadow: 0 0 0 2px #4f46e5;
}

.e-card-virtual-skeleton-wrapper,
.e-card-skeleton-wrapper {
  background: #f3f4f6;
}