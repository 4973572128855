/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
//@import '~quill/dist/quill.snow.css';

/* Start syncfusion styles */
// .light {
//     @import '@syncfusion/ej2-grids/styles/tailwind.css';
// }
// .dark {
//     @import '@syncfusion/ej2-grids/styles/tailwind-dark.css';
// }

@import '@syncfusion/ej2-grids/styles/tailwind.css';
// @import '@syncfusion/ej2-grids/styles/tailwind-dark.css';


@import '@syncfusion/ej2-notifications/styles/tailwind.css';  
@import '@syncfusion/ej2-base/styles/tailwind.css';  
@import '@syncfusion/ej2-buttons/styles/tailwind.css';  
@import '@syncfusion/ej2-calendars/styles/tailwind.css';  
@import '@syncfusion/ej2-dropdowns/styles/tailwind.css';  
@import '@syncfusion/ej2-inputs/styles/tailwind.css';  
@import '@syncfusion/ej2-navigations/styles/tailwind.css';
@import '@syncfusion/ej2-popups/styles/tailwind.css';
@import '@syncfusion/ej2-splitbuttons/styles/tailwind.css';
@import '@syncfusion/ej2-notifications/styles/tailwind.css';
@import '@syncfusion/ej2-kanban/styles/tailwind.css';
@import "@syncfusion/ej2-icons/styles/material.css";
/* End syncfusion styles */


@import 'ngx-toastr/toastr';